import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";

import PointOfSalePricesWrapper from "@components/layouts/pointOfSalePricesWrapper";
import MainBanner from "@components/pointofsaleprices/mainBanner/mainBanner";

const WhyUs = dynamic(() => import("@components/pointofsaleprices/whyUs"));
const OffersList = dynamic(() => import("@components/shared/offersList"));
const SystemBasics = dynamic(
    () => import("@components/pointofsaleprices/systemBasics"),
);
const SystemBenefits = dynamic(
    () => import("@components/pointofsaleprices/systemBenefits"),
);

export default function PointOfSaleCostHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <PointOfSalePricesWrapper>
            <MainBanner />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <SystemBasics />
            <SystemBenefits />
            <WhyUs />
        </PointOfSalePricesWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
